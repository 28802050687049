import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Elements
import ButtonDefault from 'components/elements/ButtonDefault'

// Components
import Showcase from 'components/elements/Showcase'

// Third Party
import styled from 'styled-components'

const StyledNews = styled.div``

const News = ({ className }) => {
  const {
    news: {
      nodes: news
    }
  } = useStaticQuery(graphql`{
    news: allWordpressPost {
      nodes {
        ...NewsPreviewFrag
      }
    }
  }`)

  const [chunk, setChunk] = useState(4)

  return (
    <StyledNews className={`container ${className ? `${className}` : ``}`}>
      <div className="row">
        {news.slice(0, chunk).map(n => (
          <div className="col-lg-6 pb-lg-5 pb-3 d-flex justify-content-center">
            <Showcase showcase={n} key={n.wordpress_id} />
          </div>
        ))}
      </div>
      {news.length > chunk && (
        <div className="d-flex pb-lg-5 pb-3 justify-content-center">
          <ButtonDefault onClick={() => setChunk(chunk + 2)}>
            Bekijk meer berichten
          </ButtonDefault>
        </div>
      )}
    </StyledNews>
  )
}

export default News